<template>
	<div class="uw-banner">
		<div class="uw-banner-slider" v-if="slides.length > 1">
			<a
				v-for="(slide, i) in slides"
				:key="i"
				:href="slide.link"
				:class="[
				'uw-banner-slider__slide',
					(i == hoveredItem) ? 'is-hovered' : ''
				]"
				target="_blank"
				:data-count="i + 1"
				:style="{ 'background-image': 'url(' + slide.image + ')' }"
				@mouseover="changeSlide(i)"
				@click="clickSlide(i)"
			>
			</a>
		</div>
		<div v-else-if="slides.length == 1">
			<a
				:href="slides[0].link"
				class="uw-banner-slider__link"
			>
				<img :src="slides[0].image" class="uw-banner-slider__image" alt="" width="100%" height="115">
			</a>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'StartBanner',

	props: {
		banners: Array,
		urls: Array,
		complete_task_aside_url: String
	},

	data() {
		return {
		slides: [],
		links: [],
		hoveredItem: 0,
		isSlideChanging: false
		}
	},

	created() {
		const urls = this.urls;

		this.slides = this.banners.map((image, index) => {
			return {
				link: urls[index] ? urls[index] : '#',
				image: image
			}
		})
	},

	methods: {
		clickSlide(i) {
			if (this.slides[i].link == 'tg://resolve?domain=edusondaily&utm_source=telegram_banner') {
				this.completeTaskAside();
			}
			if (i != this.hoveredItem) {
				this.hoveredItem = i;
			} else {
				location.href = '#';
			}
		},

		changeSlide(i) {
			if (!this.isSlideChanging && this.hoveredItem != i) {
				this.hoveredItem = i;
				this.isSlideChanging = true;
				setTimeout(() => this.isSlideChanging = false, 500);
			}
		},

		completeTaskAside() {
			axios.request({
				response_type: "json",
				headers: {
				"X-Requested-With": "XMLHttpRequest",
				"X-CSRF-Token": document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute("content")
				},
				method: "POST",
				url: this.complete_task_aside_url,
				data: { number: 'third' }
			})
			.then(response => {
				this.show = false;
				})
				.catch(errors => {
				console.log(errors.response.data);
			});
		},
	}
}
</script>
