<template>
	<div class="main-header-bottom">
		<div class="page-inner page-inner--wide main-header-bottom-inner">
			<div v-if="is_show_theme" class="main-header-theme-course theme-course">
				<button class="theme-course__btn">{{ $t('buttons.select_topic') }}</button>
				<div class="theme-course__holder">
					<div class="theme-course__header">
						<h3 class="theme-course__title">
							<a href="/presets" class="theme-course__title-link link-btn">
								{{ $t('buttons.presets') }}
							</a>
						</h3>
					</div>
					<div class="theme-course__content">
						<div class="theme-course__column">
							<ul class="theme-course__list">
								<li
									v-for="(topic, index) in firtstTopics"
									:key="'1header-' + topic.id + '-' + index"
									class="theme-course__item"
								>
									<a :href="'/topics/' + topic.id" class="theme-course__link">
										{{ topic.name }}
									</a>
								</li>
							</ul>
						</div>
						<div class="theme-course__column">
							<ul class="theme-course__list">
								<li
									v-for="(topic, index) in secondTopics"
									:key="'2header-' + topic.id + '-' + index"
									class="theme-course__item"
								>
									<a :href="'/topics/' + topic.id" class="theme-course__link">{{ topic.name }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="main-header-search">
				<div action class="main-header-search-form">
					<div class="main-header-search-field">
						<input
							v-model="searchInput"
							type="text"
							class="main-header-search-input"
							id="main_header_search"
							:placeholder="$t('placeholders.courses_search_placeholder')"
							autocomplete="off"
							v-on:keyup.enter="search()"
						/>
						<a :href="searchQuery" class="main-header-search-submit">
							<span class="visually-hidden">{{ $t('buttons.search') }}</span>
						</a>
						<button v-if="false" class="main-header-search-remove-btn remove-btn">
							<span class="visually-hidden">{{ $t('buttons.clear') }}</span>
						</button>
					</div>
				</div>
			</div>
			<div v-if="sign_in" class="main-header-note">
				<a :href="my_learning_plan_path" class="main-header-note-link">
					{{ $t('learning_plan_completed_to') }} {{ learning_plan_result }}%
				</a>
			</div>
			<div v-else class="main-header-note">
				{{ $t('have_an_account') }}
				<template v-if="current_company == 'phosagro'">
					<a href="https://phosagro.eduson.tv/ru/transfer-phosagro" class="link-btn">{{ $t('buttons.signin') }}</a>
				</template>
				<template v-else-if="current_company == 'alcon'">
					<a href="https://alcon.okta.com/app/alcon_edusonelearningplatform_1/exk17d9t9b7j0Slyc358/sso/saml?SAMLRequest=jZG9bsIwFEZfJZunYJMEQiyChMqCRBdoO3RB144pKY6d%2Bt4g%2BvZNQJVoh6qTZes75%2F54jtDYVi47Orqt%2BegMUrRENIFq7x68w64xYWfCudbmebsp2ZGoRck5WO3dyFQd9gededczyKH38EHJNVirQJ9YtOqdtYNB%2BBv3J4KR9g2Htr097W9GYw0EV7u31gIdfGj2Y24up3FeFVSo%2FF3s7KdOJzOO6K%2F1WLRelWw%2FUaoo1AxikSVVnGWVimfTcRqrwzTVVQqZKnQfRezM2iGBo5IlIslikcdJ9iRymeVSiFcWvfTjXDtORoJFl8Y6lEOhknXBSQ9Yo3TQGJSk5W75uJF9UML36u6R9m%2BmDZ689pYt5kNaXrsLi%2F8uujEEFRDM%2BT19u%2F382MUX" class="link-btn">{{ $t('buttons.signin') }}</a>
				</template>
				<template v-else>
					<button class="link-btn j-login j-show-login-part-popup" type="button">{{ $t('buttons.signin') }}</button>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'CoursesCatalogHeader',

	props: {
		topics: Array,
		my_learning_plan_path: String,
		search_url: String,
		learning_plan_result: String,
		courses_index: {
			type: Boolean,
			default: false
		},
		sign_in: {
			type: Boolean,
			default: false
		},
		is_show_theme: Boolean,
		current_company: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			сoursesCatalogName: 'Работает',
			searchInput: '',
			showThemes: false,
			loading: true
		};
	},

	computed: {
		filtered_topics() {
			return this.topics;
		},

		firtstTopics() {
			return this.filtered_topics.splice(
				0,
				Math.ceil(this.filtered_topics.length / 2)
			);
		},

		secondTopics() {
			return this.filtered_topics;
		},

		searchQuery() {
			if (this.searchInput) {
				return this.search_url + '?q=' + this.searchInput;
			}
		}
	},

	methods: {
		search1(q) {
			axios.request({
				responseType: 'json',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'X-CSRF-Token': document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute('content')
				},
				url: this.search_url,
				method: 'GET',
				data: {
					q: q
				}
			});
		},

		search() {
			if (this.searchInput) {
				window.location.href = this.search_url + '?q=' + this.searchInput;
			}
		},

		scrollHeader() {
			let header = $('.main-header');
			let headerBottom = $('.main-header-bottom');
			let headerHeight = header.height();
			let headerBottomTop = headerBottom.offset().top;
			$(window).scroll(function(e) {
				let st = $(this).scrollTop();
				if (st > headerBottomTop) {
					header.height(headerHeight);
					headerBottom.addClass('is-fixed');
				} else {
					headerBottom.removeClass('is-fixed');
				}
			});
		}
	},

	mounted() {
		if (this.courses_index) this.scrollHeader();
	},
};
</script>
