<template>
	<p class="bold-font large-font-size icon icon--regular icon--materials mt-15">
		{{ $t('homework') }}
	</p>
	<h1 class="longread-title">{{ homework_title }}</h1>
	<div class="content-with-nav">
		<div class="content-with-nav__main">
			<div class="content-with-nav__card content-with-nav__card--with-pads">
				<div class="longread longread--no-pads j-longread j-fullsize-images" v-html="homework_body" />
			</div>

			<div class="content-with-nav__card content-with-nav__card--with-pads mt-20">
				<template v-if="homework_attempts.length">
					<div v-for="(attempt, index) in homework_attempts" :key="attempt.id" class="content-with-nav__item">
						<div class="label mb-5">
							Попытка {{ index + 1 }}
							<span v-if="attempt.control && attempt.status === 'success_done'" class="icon icon--check icon--tiny icon--high-color ml-10"></span>
							<span v-if="attempt.control && attempt.status !== 'success_done'" class="icon icon--tiny icon--remove icon--red-color ml-10"></span>
						</div>
						<div v-if="attempt.answer" class="thin-font">{{ attempt.answer }}</div>
						<div class="light-font-color small-font-size mt-10">{{ setDatetime(attempt.created_at) }}</div>
						<template v-if="attempt.attachments.length">
							<h3 class="label mb-5 mt-20">Прикрепленные файлы</h3>
							<div class="files-preview-list">
								<div
									v-for="(attachment, index) in attempt.attachments"
									:key="index"
									class="files-preview-list__item"
								>
									<span class="files-preview-list__check icon icon--check icon--tiny icon--high-color" />
									<a :href="attachment.file_url_original" target="_blank" rel="noopener noreferrer" class="files-preview-list__name">{{ attachment.file_file_name }}</a>
								</div>
							</div>
						</template>
						<div v-if="attempt.control" class="mt-20 ml-20">
							<h3 class="label mb-5 mt-20">{{ $t('curators_response') }}</h3>
							<p class="light-font-color">{{ attempt.control }}</p>
						</div>

						<div v-if="['sent', 'checking', 'in_crosscheck', 'checked', 'sending'].includes(attempt.status)" class="inline-note inline-note--round mt-20">
							{{ $t('messages.answer_received') }}
						</div>
						<div v-if="attempt.status === 'failed_done'" class="inline-note inline-note--error inline-note--round mt-20">
							{{ $t('messages.homework_attempt_error') }}
						</div>
						<div v-if="attempt.status === 'success_done'" class="inline-note inline-note--success inline-note--round mt-20">
							{{ $t('messages.homework_accepted') }}
						</div>
					</div>
				</template>

				<template v-if="isShowForm">
					<label for="id-homework-user-answer" class="labeled-field labeled-field--regular-font labeled-field--bordered mt-20">
						<span class="labeled-field__label">{{ $t('placeholders.your_answer_homework') }}</span>
						<textarea
							id="id-homework-user-answer"
							class="labeled-field__input"
							v-model="currentAnswer"
						></textarea>
					</label>
					<button @click="showFileInput" class="link-btn mt-15" type="button">{{ $t('buttons.add_file') }}</button>
					<div class="mt-20 hide" ref="filesWrapper">
						<div class="upload-files upload-files--small">
							<input @change="filesChange($event.target.files)" type="file" class="upload-files__input" multiple>
							<label for="" class="upload-files__label">
								{{ $t('messages.select_file') }}
							</label>
						</div>

						<div v-if="previews" class="files-preview-list mt-20">
							<div
								v-for="(preview, index) in previews"
								:key="index"
								class="files-preview-list__item"
							>
								<!-- <Loader v-if="preview.isLoading" class="files-preview-list__loader loader--small" /> -->
								<span class="files-preview-list__check icon icon--check icon--tiny icon--high-color" />
								<div class="files-preview-list__name">{{ preview.name }}</div>
								<!-- <div class="light-font-color small-font-size ml-10">{{ preview.size }} КиБ</div> -->
								<button
									@click="removeAttachement(index)"
									class="files-preview-list__remove-btn remove-btn remove-btn--red remove-btn--round-white-bg"
									type="button"
								>
									<span class="visually-hidden">Удалить файл</span>
								</button>
							</div>
						</div>
					</div>

					<div class="mt-20">
						<button
							@click="submitData"
							class="regular-btn action-btn"
							type="button"
						>
							{{ $t('buttons.send') }}
						</button>
						<button
							@click="resetData"
							class="regular-btn white-btn ml-10"
							type="button"
						>
							{{ $t('buttons.reset') }}
						</button>
					</div>
				</template>
			</div>
		</div>

		<div class="content-with-nav__aside">
			<div v-if="homework_files.length" class="content-with-nav__card content-with-nav__card--with-pads content-with-nav__card--action-bg">
				<h3 class="label mb-10">{{ $t('homework_files') }}</h3>
				<ul class="materials-list">
					<li v-for="homework_file in homework_files" class="materials-item materials-item--docs mb-10">
						<a :href="homework_file.file" class="link-btn link-btn--dark-font">{{ homework_file.caption }}</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<FullsizeImage />
</template>

<script>
import axios from 'axios';
import hljs from 'highlight.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import Loader from '../shared/Loader.vue';
import FullsizeImage from './FullsizeImage.vue';

export default {
	name: 'Homework',

	props: {
		homework_body: {
			type: String,
			required: true
		},
		homework_title: {
			type: String,
			default: ''
		},
		homework_files: {
			type: Object,
			default: ''
		},
		homework_attempts: {
			type: Object,
			default: ''
		},
		homework_attempts_path: {
			type: String
		}
	},

	components: {
		Loader,
		FullsizeImage
	},

	data() {
		return {
			players: [],
			attachments: [],
			previews: [],
			currentAnswer: '',
			isShowForm: true,
			sentStatusValues: ['sent', 'checking', 'in_crosscheck', 'checked', 'sending', 'success_done']
		}
	},

	mounted() {
		this.highlightCode();
		this.initVideoJs();
		this.initImages();

		if (this.sentStatusValues.includes(this.homework_attempts.at(-1).status)) {
			this.isShowForm = false
		}
	},

	methods: {
		showFileInput() {
			this.$refs.filesWrapper.classList.toggle('hide');
		},

		setDatetime(attemptDate) {
			let date = new Date(Date.parse(attemptDate));
			return moment(date).format('D MMMM YYYY, h:mm');
		},

		filesChange(fileList) {
			if (!fileList.length) return;

			for (let index = 0; index < fileList.length; index++) {
				let file = fileList[index];
				this.attachments.push(file);
				this.createPreview(file)
			}
		},

		createPreview(file) {
			let preview = {};
			preview.name = file.name;
			preview.size = file.size;
			this.previews.push(preview);
		},

		removeAttachement(index) {
			this.previews.splice(index, 1);
			this.attachments.splice(index, 1)
		},

		showImage(name) {
			return /\.(jpe?g|png|gif)$/i.test(name);
		},

		submitData() {
			this.sendData();
		},

		resetData() {
			this.currentAnswer = '';
			this.attachments = [];
			this.previews = [];
		},

		sendData() {
			let formData = new FormData();

			formData.append('homework_attempt[answer]', this.currentAnswer);

			if (this.attachments && this.attachments.length > 0) {
				this.attachments.forEach((file, index) => {
					formData.append(`homework_attempt[attachments_attributes][${index}][file]`, file);
				});
			}

			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content")
				},
				responseType: "json",
				url: this.homework_attempts_path,
				method: "POST",
				data: formData
			}).then((response) => {
				this.isShowForm = false;
				location.reload();
			}).catch((error) => {
				console.error(error);
			})
		},

		highlightCode() {
			document.querySelectorAll('.j-homework pre code').forEach((el) => {
				hljs.highlightElement(el);
			});
		},

		initImages() {
			document.querySelectorAll('.j-homework img').forEach((img) => {
				img.closest('p, figure').style.display = 'inline-block';
			})
		},

		initVideoJs() {
			document.querySelectorAll('.j-homework video').forEach((video) => {
				video.classList.add('video-js');

				let playBtn = document.createElement('button');
				playBtn.type = 'button';
				playBtn.className = 'play-btn';
				playBtn.innerHTML = '<span class="play-btn__icon"></span>';
				video.closest('div').appendChild(playBtn);

				let player = videojs(video, {
					autoplay: false,
					controls: true,
					preload: false,
					width: 656,
					height: 369,
					title: '',
					playbackRates: [0.5, 1, 1.25, 1.5, 2],
					sources: [
						{
							src: video.src,
							type: 'video/mp4'
						}
					]
				});

				playBtn.addEventListener('click', () => {
					player.play();
					playBtn.classList.add('not-showing');
				})

				this.players.push(player);
			})
		}
	},

	beforeDestroy() {
		this.players.forEach((player) => {
			if (player) {
				player.dispose();
			}
		})
	}
}
</script>
