<template>
	<label class="checkbox">
		<input class="checkbox__input" v-model="termAndPrivacy" @change="sendData" name="term_and_privacy" type="checkbox">
		<span v-if="current_company === 'mega'" class="checkbox__label" v-html="$t('messages.mega_term_and_privacy')" />
		<span v-else class="checkbox__label" v-html="$t('messages.term_and_privacy')" />
	</label>
</template>

<script>
import axios from 'axios';

export default {
	name: 'TermAndPrivacyForm',

	props: {
		user: { type: Object, required: true },
		url: { type: String, required: true },
		current_company: {
			type: String,
			default: ''
		},
	},

	data() {
		return {
		termAndPrivacy: this.user.term_and_privacy
		}
	},

	mounted() {
		this.$parent.openPopup();
	},

	methods: {
		sendData() {
			this.$parent.loadingOn();

			axios.request({
				response_type: 'json',
				headers: {
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				url: this.url,
				method: 'POST',
				data: { term_and_privacy: this.termAndPrivacy }
			}).then((response) => {
				response.data.term_and_privacy ? this.$parent.unDisabledBtn() : this.$parent.disabledBtn();
			})
			this.$parent.loadingOff().disabledBtn();
		}
	},
}
</script>
