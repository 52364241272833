<template>
	<section class="catalog__section">
		<div class="catalog__section-header catalog__section-header--with-link">
			<template v-if="recommended">
				<template v-if="current_company === 'megafon'">
					<h2 class="catalog__title">
						{{ $t('recommended_megafon_plan') }}
					</h2>
					<p class="catalog__right" style="width: 215px;">
						<a href="/recommended_prescriptions" class="link-btn link-btn--opacity link-btn--light-font">
							{{ $t('buttons.prescription_all') }}
						</a>
					</p>
				</template>
				<template v-else>
					<h2 class="catalog__title">
						{{ $t('recommended_programms') }}
					</h2>
					<p class="catalog__right" style="width: 290px;">
						<a href="/recommended_prescriptions" class="link-btn link-btn--opacity link-btn--light-font">
							{{ $t('buttons.all_recommended_programms') }}
						</a>
					</p>
				</template>
			</template>
			<template v-else>
				<template v-if="current_company === 'megafon'">
					<h2 class="catalog__title">
						<template v-if="!filteredSelection(selection).length">
							{{ $t('megafon_plan') }}
						</template>
						<template v-else>
							{{ $t('megafon_plan_completed_to') }}
							<span class="sub-light-font-color">{{ learning_plan_result }}%</span>
						</template>
					</h2>
					<p class="catalog__right">
						<a href="/prescriptions" class="link-btn link-btn--opacity link-btn--light-font">
							{{ $t('buttons.prescription_all') }}
						</a>
					</p>
				</template>
				<template v-else>
					<h2 class="catalog__title">
						<template v-if="is_condidate">
							<template v-if="!filteredSelection(selection).length">
								{{ $t('condidate_learning_plan') }}
							</template>
							<template v-else>
								{{ $t('condidate_learning_plan_completed_to') }}
								<span class="sub-light-font-color">{{ learning_plan_result }}%</span>
							</template>
						</template>
						<template v-else>
							<template v-if="!filteredSelection(selection).length">
								{{ $t('learning_plan') }}
							</template>
							<template v-else>
								{{ $t('learning_plan_completed_to') }}
								<span class="sub-light-font-color">{{ learning_plan_result }}%</span>
							</template>
						</template>
					</h2>
					<p v-if="groupedSelections" class="catalog__right" style="width:240px;">
						<a href="/prescriptions" class="link-btn link-btn--opacity link-btn--light-font">
							{{ $t('buttons.all_required_programms') }}
						</a>
					</p>
				</template>
			</template>
		</div>

		<div v-if="groupedSelections" class="plan-selection">
			<ol  class="plan-selection__list">
				<li v-for="(selection, indexSelection) in groupedSelections" :key="indexSelection" class="plan-selection__item" :class="selectionClass(selection)">
					<div class="plan-selection__header" @click="toggleCards($event)">
						<div class="plan-selection__progress" :style="`background-image: conic-gradient(var(--high) ${calcDegrees(selection)}deg, transparent ${calcDegrees(selection)}deg)`">
							<span>{{ quantityCompletedCourses(selection) }}/{{ selection.items.length }}</span>
						</div>
						<h3 class="plan-selection__title">
							<span class="plan-selection__counter">{{ indexSelection + 1 }}.</span>
							{{ selection.program_title }}
						</h3>
						<!-- <div class="plan-selection__description">{{ selection.description }}</div> -->
					</div>
					<div v-if="selection.items.length" class="plan-selection__cards">
						<template v-for="(prescription, indexItem) in selection.items" :key="indexItem">
							<PlanInlineItem
								:prescription="prescription"
								:currentCompany="current_company"
								:recommended="recommended"
								:indexSelection="indexSelection"
								:indexItem="indexItem"
							/>
						</template>
					</div>
				</li>
			</ol>
		</div>
		<template v-else>
			<template v-if="recommended">
				<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.recommended_megafon_1') }}</p>
					<p class="message-card__title">{{ $t('messages.recommended_megafon_2') }}</p>
				</div>
				<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.no_recommended_training') }}</p>
					<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
					<a href="/courses" class="regular-btn action-btn">
						{{ $t('buttons.choose_course') }}
					</a>
				</div>
			</template>
			<template v-else>
				<div v-if="current_company === 'megafon'" class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.megafon_1') }}</p>
					<p class="message-card__title">{{ $t('messages.megafon_2') }}</p>
				</div>
				<div v-else class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
					<p class="message-card__label">{{ $t('messages.no_training') }}</p>
					<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
					<a href="/courses" class="regular-btn action-btn">
						{{ $t('buttons.choose_course') }}
					</a>
				</div>
			</template>
		</template>
	</section>
</template>

<script>
import PlanInlineItem from "./PlanInlineItem.vue";

export default {
	name: "PlanSelection",

	components: {
		PlanInlineItem
	},

	props: {
		selection: Array,
		learning_plan_result: String,
		newCourses: {
			type: Boolean,
			default: false,
		},
		current_company: {
			type: String,
			default: ''
		},
		recommended: {
			type: Boolean,
			default: false
		},
		is_condidate: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			loading: true,
			isContinue: false,
			currentLocale: window.currentLocale,
		};
	},

	mounted() {

	},

	computed: {
		groupedSelections() {
			const selection = this.selection;

			if (selection.length) {
				selection.sort((a, b) => new Date(a.date_from) - new Date(b.date_from))

				return selection.reduce((acc, item) => {
					const existingGroup = acc.find(group => group.program_title === item.program_title);

					if (existingGroup) {
						existingGroup.items.push(item);
					} else {
						acc.push({
							program_title: item.program_title,
							description: item.description,
							items: [item]
						});
					}

					return acc;
				}, []);
			}

			return false;
		},
	},

	methods: {
		filteredSelection(selection) {
			return selection.sort((a, b) => a.program_title.localeCompare(b.program_title));
		},

		quantityCompletedCourses(selection) {
			let quantity = 0;

			selection.items.forEach(item => {
				if (item.result === 100) quantity +=1;
			});

			return quantity;
		},

		calcDegrees(selection) {
			return 360 / selection.items.length * this.quantityCompletedCourses(selection);
		},

		toggleCards(e) {
			console.log(e.target)
			console.log(e.target.closest('.plan-selection'))

			e.target.closest('.plan-selection__item').classList.toggle('is-closed');
		},

		selectionClass(selection) {
			if (this.quantityCompletedCourses(selection) === selection.items.length) {
				return 'is-closed';
			}

			let expiredСourses = selection.items.filter((item) => {
				return item.how_many_days_left == 0;
			});

			console.log(expiredСourses)
			console.log(selection.items)

			if (expiredСourses.length === selection.items.length) {
				return 'is-closed';
			}
		}
	}
};
</script>
