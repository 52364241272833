<script>
import hljs from 'highlight.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

export default {
	name: 'Post',

	data() {
		return {
			headings: '',
			navItems: [],
			players: [],
		}
	},

	mounted() {
		this.highlightCode();
		this.initVideoJs();
		this.initImages();
	},

	methods: {
		highlightCode() {
			document.querySelectorAll('.j-post pre code').forEach((el) => {
				hljs.highlightElement(el);
			});
		},

		initImages() {
			document.querySelectorAll('.j-post img').forEach((img) => {
				img.closest('p, figure').style.display = 'inline-block';
			})
		},

		initVideoJs() {
			document.querySelectorAll('.j-post video').forEach((video) => {
				video.classList.add('video-js');

				let playBtn = document.createElement('button');
				playBtn.type = 'button';
				playBtn.className = 'play-btn';
				playBtn.innerHTML = '<span class="play-btn__icon"></span>';
				setTimeout(() => {
					video.closest('div').appendChild(playBtn);
				}, 300)

				let player = videojs(video, {
					autoplay: false,
					controls: true,
					preload: false,
					width: 656,
					height: 369,
					title: '',
					playbackRates: [0.5, 1, 1.25, 1.5, 2],
					sources: [
						{
							src: video.src,
							type: 'video/mp4'
						}
					]
				});

				playBtn.addEventListener('click', () => {
					player.play();
					playBtn.classList.add('not-showing');
				})

				this.players.push(player);
			})
		}
	},

	beforeDestroy() {
		this.players.forEach((player) => {
			if (player) {
				player.dispose();
			}
		})
	}
}
</script>

