<template>
	<h1 class="event__title">{{ live_event.title }}</h1>
	<p class="event__descr">{{ t.live_events.face_to_face_event }}</p>
	<div class="event__row mt-20">
		<a :href="live_event.calendar_url" class="small-btn white-btn mr-5">&lt {{ t.live_events.buttons.calendar }}</a>
		<a v-if="can_edit_event" :href="live_event.edit_event_url" class="small-btn action-btn">{{ t.live_events.buttons.edit }}</a>
		<button v-if="!live_event.over && !live_event.is_cancelled" @click="cancelledEvent()" class="small-btn red-btn ml-5" type="button">{{ t.live_events.buttons.cancelled }}</button>
		<a v-if="can_edit_event && live_event.over" :href="live_event.report_url" class="small-btn white-btn icon icon--small icon--download icon--white-color ml-auto">{{ t.live_events.buttons.report }}</a>
	</div>
	<div class="event-stats mt-30">
		<ul class="event-stats__list">
			<li class="event-stats__item event-stats__item--calendar" :class="[live_event.over ? 'event-stats__item--past-event' : '']">
				<span v-if="live_event.is_cancelled">
					{{ t.live_events.event }} {{ t.live_events.status.cancelled }}
				</span>
				<span v-else>
					{{ t.live_events.event }} {{ live_event.over ? t.live_events.status.took_place : t.live_events.status.will_take_place }} {{ dateToDayAndMonthFormat(live_event.date) }} {{ t.live_events.on_time }} {{ live_event.start_time }} – {{ live_event.end_time }}
				</span>
				<div class="popover popover--hover popover--middle-card popover--right-top" v-if="!live_event.over && !live_event.is_cancelled">
					<div class="popover__btn popover__btn--with-arrow link-btn">{{ t.live_events.add_to_calendar }}</div>
					<div class="popover__panel">
						<a class="popover__link mb-5" :href="exportToGoogleUrl(live_event)" target="_blank" rel="nofollow">{{ t.live_events.export_to_google }}</a>
						<a class="popover__link" :href="live_event.export_live_event_url" target="_blank" rel="nofollow">{{ t.live_events.export_to_ics }}</a>
					</div>
				</div>
			</li>
			<li v-if="live_event.place" class="event-stats__item event-stats__item--pin">
				<span v-if="live_event.event_type == 'webinar'">
					{{ t.live_events.webinar_link }}:
					<a :href="live_event.place" target="_blank">{{ live_event.place }}</a>
				</span>
				<span v-else>
					{{ t.live_events.place }}: {{ live_event.place }}
				</span>
			</li>
			<li v-if="live_event.moderator" class="event-stats__item event-stats__item--mic">
				{{ live_event.moderator }}
			</li>
			<li v-if="live_event.description !== '<p></p>'" v-html="live_event.description" class="event-stats__item event-stats__item--info"></li>
			<li v-if="live_event.attachments.length > 0" class="event-stats__item event-stats__item--download ">
				<div v-for="attachment in live_event.attachments" :key="attachment.id">
					<a :href="attachment.url" target="_blank">{{ attachment.name }}</a>
					<span class="sub-light-font-color">({{ attachment.extension }})</span>
				</div>
			</li>
		</ul>
	</div>

	<LiveEventUsers
		class="mt-30"
		:live_event_is_over="live_event.over"
		:live_event_cancelled="live_event.is_cancelled"
		:live_event_users="live_event.live_event_users"
		:admin="can_edit_event"
	></LiveEventUsers>

	<div class="mt-15 text-right" v-if="can_edit_event && !live_event.over">
		<a :href="live_event.invites_url" class="regular-btn start-btn mr-10">{{ t.live_events.buttons.invite }}</a>
	</div>

	<div v-if="live_event.video_url" class="event__video-holder mt-30">
		<VideoPreview
			:options="{
				autoplay: false,
				preload: true,
				controls: true,
				loop: false,
				width: 672,
				height: 378,
				mute: false,
				mute_message: false,
				sources: [
					{ src: live_event.video_url }
				],
				playbackRates: [0.5, 1, 1.25, 1.5, 2],
			}"
		></VideoPreview>
	</div>

	<div v-if="can_edit_event" class="mt-30">
		<h2 class="label mb-5">
			{{ !live_event.video_url ? 'Добавить видеозапись встречи' : 'Заменить видеозапись встречи' }}
		</h2>
		<div class="upload-files">
			<input type="file" @change="handleFileUpload" accept="video/*" class="upload-files__input" >
			<span class="upload-files__label">
				{{ !selectedFile ? 'Кликните или перенесите сюда файл' : 'Файл выбран, нажмите на кнопку «Отправить»' }}
			</span>
		</div>

		<button
			@click="uploadVideo"
			:disabled="!selectedFile"
			class="regular-btn action-btn mt-10"
			:class="{ 'is-loading-btn': uploadLoading }"
		>
			{{ $t('buttons.send') }}
		</button>
	</div>
</template>

<script>
import axios from 'axios';
import LiveEventUsers from './Users.vue';
import VideoPreview from '../Courses/VideoPreview.vue';

export default {
	name: 'LiveEventShow',

	components: {
		LiveEventUsers,
		VideoPreview
	},

	props: {
		live_event: {
			type: Object,
			required: true
		},
		can_edit_event: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			t: I18n.t,
			selectedFile: null,
			uploadLoading: false,
			event: []
		}
	},

	methods: {
		cancelledEvent() {
			if (confirm(this.t.live_events.are_you_sure)) {
				this.$parent.loadingOn = true;

				axios.delete(this.live_event.cancelled_url, {
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Content-Type': 'application/json',
						'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
					}
				}).then((response) => {
					this.$parent.currentLiveEvent = response.data;
					this.$parent.loadingOn = false;
				})
			}
		},

		exportToGoogleUrl(live_event) {
			let details = [live_event.description, live_event.live_events_list_url].join(' ');
			let export_link = [
				"http://www.google.com/calendar/event?action=TEMPLATE",
				`text=${live_event.title}`,
				`dates=${live_event.export_start_date_time}/${live_event.export_end_date_time}`,
				`details=${details}`,
				`location=${live_event.place}`,
			];

			return export_link.join('&');
		},

		dateToDayAndMonthFormat(date) {
			return moment(date).format('D MMMM');
		},

		handleFileUpload(event) {
			this.selectedFile = event.target.files[0];
		},

		uploadVideo() {
			if (!this.selectedFile) return;

			const formData = new FormData();
			formData.append('video', this.selectedFile);

			this.uploadLoading = true;

			axios.post(`/live_events/${this.live_event.id}/upload_video`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				}
			})
				.then((response) => {
					this.$parent.currentLiveEvent = response.data;
					this.selectedFile = null;
					this.uploadLoading = false;
					location.reload();
				})
				.catch((error) => {
					console.error(error);
					alert("Ошибка. Видео не загрузилось");
				})

		}
	},
}
</script>


