<template>
	<div class="service-card">
		<header class="service-card__header">
			<div class="service-card__top">
				<div class="service-card__breadcrumbs">
					<a :href="career_constructors_url" class="link-btn">
						{{ t.careers.editor.title }}
					</a>
				</div>
				<p class="service-card__label">
					{{ t.careers.editor.adding_career_aspects }}
				</p>
				<h1 class="service-card__title">{{ career.name }}</h1>

				<div class="inline-stats mt-10">
					<div class="status-label mr-20" v-if="!career.published">
						{{ t.careers.editor.not_published }}
					</div>
					<div class="status-label status-label--publish mr-20" v-if="career.published">
						{{ t.careers.editor.published }}
					</div>
					<a :href="edit_career_constructor_url" class="link-btn">
						{{ t.careers.editor.edit_career }}
					</a>
				</div>
				<div class="service-card__cover-wrap">
					<img :src="logo" alt="preset.name" class="service-card__cover" loading="lazy" />
				</div>
			</div>
			<div class="service-card__descr longread longread--no-pads scrollable-shadow"
				v-if="(career.description != '')"
				v-html="career.description"
			></div>
		</header>

		<div class="service-card__content">
			<section
				class="service-card__section skills-section"
				:class="{'skills-section--sub' : aspect.aspect_type === 'sub' }"
				v-for="(aspect, index) in career.aspects"
				:key="'career-aspect' + index"
			>
				<div class="skills-section__header">
					<div class="skills-section__icon-holder">
						<img
						:src="aspect.icon_url"
						:alt="aspect.name"
						class="skills-section__icon"
						loading="lazy"
						/>
					</div>
					<h3 class="skills-section__title" :title="aspect.name">
						{{ aspect.name }}
					</h3>
					<div class="inline-stats mt-5">
						<span v-if="aspect.aspect_type !== 'main'" class="inline-stats__item inline-stats__item--duration">
							{{ aspect.duration }} {{ t.careers.editor.h_learning }}
						</span>
						<a @click="showAspectForm(aspect)" class="link-btn">
							{{ t.careers.editor.edit_aspect }}
						</a>
					</div>
					<span v-if="aspect.aspect_type !== 'main'" class="skills-section__results">0&thinsp;/&thinsp;{{ aspect.crafts.length }}</span>
					<p class="skills-section__descr longread longread--no-pads" v-if="aspect.description" v-html="aspect.description"></p>
				</div>
				<ul class="skills-section__list">
					<li
						v-for="(craft, index) in aspect.crafts"
						:key="index"
						class="skills-section__item"
					>
						<article class="catalog-card">
							<a :href="'/courses/' + craft.course.id" class="catalog-card__image-link">
								<img
									:src="craft.course_logo"
									alt="preset.name"
									class="catalog-card__image"
									loading="lazy"
								/>
								<div
									class="catalog-card__preview j-course-card-preview-link"
									:data-course-id="craft.course.id"
									data-url="/ru/courses/"
								></div>
							</a>
							<div class="catalog-card__content">
								<h3 class="catalog-card__title">
									<a :href="'/courses/' + craft.course.id" class="catalog-card__title-link">
										{{ craft.name }}
									</a>
								</h3>
								<div class="catalog-card__footer">
									<p class="catalog-card__footer-text">
										{{ craft.course.duration_minutes }} мин.
									</p>
								</div>
							</div>
						</article>
					</li>
				</ul>
			</section>
			<section class="service-card__section skills-section skills-section--add-skill">
				<button
					@click="showAspectFormByUrl(new_career_constructors_url)"
					class="skills-section__header link-btn"
				>
					<div class="skills-section__header-bar">
						<div class="skills-section__icon-holder"></div>
						<div class="skills-section__header-descr">
							<h3 class="skills-section__title">
								{{ t.careers.editor.add_aspect }}
							</h3>
						</div>
					</div>
				</button>
			</section>
		</div>
		<footer class="service-card__scroll-footer" v-if="!career.published">
			<div class="service-card__scroll">
				<div class="service-card__scroll-left">
					<p class="service-card__scroll-title">
						{{ t.careers.editor.changes_saved }}
					</p>
					<p class="service-card__scroll-text">
						{{ t.careers.editor.changes_saved_auto }}
					</p>
				</div>
				<button class="regular-btn start-btn" @click="togglePublish">
					{{ t.careers.editor.publish }}
				</button>
			</div>
		</footer>
		<footer class="service-card__scroll-footer" v-if="career.published">
			<div class="service-card__scroll">
				<div class="service-card__scroll-left">
					<a v-if="!careerSavedMessage" :href="fast_assignments_url" class="regular-btn action-btn mr-10">
						{{ t.careers.editor.adding_to }}
					</a>

					<button class="regular-btn start-btn mr-10" @click="submitCareerForm" v-if="careerChanged">
						{{ t.careers.editor.save_changes }}
					</button>
					<a v-if="!careerSavedMessage" @click="cancelEditing" class="regular-btn white-btn mr-10">
						Отмена
					</a>
					<a :href="career_url" class="regular-btn white-btn" v-if="!careerSavedMessage">
						{{ t.careers.editor.open_in_catalog }}
					</a>
					<div class="service-card__scroll-left" v-if="careerSavedMessage">
						<p class="service-card__scroll-message">
							{{ t.careers.editor.changes_saved }}

							<a @click="careerSavedMessage = false">
								{{ t.careers.editor.continue }} </a
							>.
						</p>
					</div>
				</div>
				<button class="regular-btn red-btn" @click="togglePublish">
					{{ t.careers.editor.unpublished }}
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: "CareerForm",

	props: {
		open_preset_form: Boolean,
		default_assignee_ids: Array,
		initial_career: Object,
		logo: String,
		edit_career_constructor_url: String,
		career_constructor_url: String,
		career_aspects_url: String,
		career_constructors_url: String,
		career_url: String,
		new_career_constructors_url: String,
		authenticity_token: String,
		fast_assignments_url: String
	},

	data() {
		return {
			career: this.initial_career,
			careerChanged: false,
			careerSavedMessage: false,
			formElement: null,
			assignment: {},
			form_action: {},
			datepickers: {},
			errors: {},
			utf8: "",
			test: "",
			t: I18n.t
		};
	},

	methods: {
		showAspectForm(aspect) {
			this.emitter.emit("show_aspect_form", aspect);
		},

		showAspectFormByUrl(url) {
			this.emitter.emit("show_aspect_form_by_url", url);
		},

		submitAspectForm(aspect) {
			this.careerChanged = true;
			let careerAspectIndex = this.career.aspects.findIndex(function(
				careerAspect,
				index
			) {
				if (careerAspect.id == aspect.id) return true;
			});
			if (this.career.aspects[careerAspectIndex]) {
				this.career.aspects[careerAspectIndex] = aspect;
			} else {
				this.career.aspects[this.career.aspects.length] = aspect;
			}
			this.career.aspects.sort((a, b) => a.id - b.id);
			this.submitCareerForm();
		},

		deleteAspect(aspect) {
			this.careerChanged = true;
			this.career.aspects = this.career.aspects.filter(
				item => item.id !== aspect.id
			);

			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content")
				},

				url: this.career_aspects_url + "/" + aspect.id,
				method: "DELETE"
			})
				.then(response => {})
				.catch(errors => {
					console.log(errors);
				});
			this.career.aspects.sort((a, b) => a.id - b.id);
		},

		togglePublish() {
			this.career.published = !this.career.published;
			this.submitCareerForm();
		},

		closePopup() {
			console.log("closePopup");
			this.$parent.closePopup();
		},

		submitCareerForm() {
			axios({
				response_type: "json",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content")
				},

				url: this.career_constructor_url,
				method: "PUT",

				data: {
					utf8: this.utf8,
					authenticity_token: this.authenticity_token,
					career: this.career
				}
			})
				.then(response => {
					this.career = response.data.career;
					this.careerChanged = false;
					this.careerSavedMessage = true;
					setTimeout(() => (this.careerSavedMessage = false), 2000);
					this.career.aspects.sort((a, b) => a.id - b.id);
				})
				.catch(errors => {
					console.log(errors);
				});
		},

		cancelEditing() {
			this.career = this.initial_career;
			this.submitCareerForm();
		},

		scrollFooter() {
			let page = document.querySelector(".page");
			let container = document.querySelector(
				".service-card__scroll-footer"
			);
			let item = document.querySelector(".service-card__scroll");
			let pageHeight = page.scrollHeight;
			let containerHeight = container.offsetHeight;
			let itemHeight = item.getBoundingClientRect().height;
			let itemTop = +item.scrollTop + +item.getBoundingClientRect().top;
			let distanse = pageHeight - +itemTop - +itemHeight;
			container.style.height = containerHeight + "px";

			window.addEventListener("scroll", function() {
				let st = window.scrollY;
				if (st < distanse) {
					item.classList.add("is-fixed");
				} else {
					item.classList.remove("is-fixed");
				}
			});
		}
	},

	mounted() {
		this.career.aspects.sort((a, b) => a.id - b.id);

		this.emitter.on("submit_aspect_form", this.submitAspectForm);
		this.emitter.on("delete_aspect", this.deleteAspect);
	},
};
</script>
