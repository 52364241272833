const demoFreeCourses = [
	{name: 'academy-business-analyst-demo', url: 'https://eduson.academy/business-analyst?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-data-science-demo', url: 'https://eduson.academy/data-scientist?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-web-developer-demo', url: 'https://eduson.academy/web-developer?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-frontend-developer-demo', url: 'https://eduson.academy/frontend?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-qa-pro-demo', url: 'https://eduson.academy/qa-autotester?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-qa-demo', url: 'https://eduson.academy/qa?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-methodist-basics-demo', url: 'https://eduson.academy/education-methodist?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-online-course-creation-demo', url: 'https://eduson.academy/online-course-creator?utm_s…ce=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-hr-manager-demo', url: 'https://eduson.academy/hr-manager?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-gen-director-demo', url: 'https://eduson.academy/gen-dir?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-com-director-demo', url: 'https://eduson.academy/com-dir?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-salesman-demo', url: 'https://eduson.academy/sales-manager?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-sales-demo', url: 'https://eduson.academy/sales?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-soft-skills-demo', url: 'https://eduson.academy/soft-skills-manager?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-graphic-designer-demo', url: 'https://eduson.academy/graphic-designer?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-internet-marketing-demo', url: 'https://eduson.academy/internet-marketing?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-copywriter-demo', url: 'https://eduson.academy/copywriter?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-mba-marketing-demo', url: 'https://eduson.academy/marketing-mba?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-accountant-demo', url: 'https://eduson.academy/accountant?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-marketplace-manager-demo', url: 'https://eduson.academy/marketplace?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-product-manager-demo', url: 'https://eduson.academy/product-manager?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-project-manager-demo', url: 'https://eduson.academy/project-manager?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-chief-of-operations-demo', url: 'https://eduson.academy/operations-director?utm_source=platform&utm_medium=banner&utm_campaign=header'},
	{name: 'academy-fin-officer-demo', url: 'https://eduson.academy/findir?utm_source=platform&utm_medium=banner&utm_campaign=header'},
];

export default demoFreeCourses;
