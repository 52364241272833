<template>
	<section class="welcome-trust">
		<div class="page-inner">
			<h2 class="welcome-trust__title">{{ $t('welcome_why_eduson_title') }}</h2>
			<template v-if="currentLocale === 'ru'">
				<Carousel
					:itemsToShow="1"
					:itemsToScroll="1"
					snapAlign="start"
					:wrapAround="true"
					class="feedback-wrapper"
				>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/rostelekom-ru@2x.png" class="feedback-logo" height="75" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first">
								Eduson — это удобный и экономически эффективный инструмент для того, чтобы обучить 140 тысяч человек по всей России.
							</p>
							<p class="feedback-text feedback-text-quote-last">
								Удобные форматы курсов и технология позволяют учиться в тот момент, когда хочется, и из любого места, где застигнет такое желание.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Никита Черкасенко</p>
								<p>Директор Департамента оценки, обучения и развития персонала</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/rzhd-logo.png" class="feedback-logo" height="55" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first">
								Лучшие электронные курсы, которые я только видел! Отличная подача материала! Несмотря на наличие западных примеров – эти примеры живые для передачи информации слушателям.
							</p>
							<p class="feedback-text feedback-text-quote-last">
								Навыки из курсов хочется применять на практике: чек-листы, формулы Excel и др.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Александр Горовых</p>
								<p>Сотрудник подразделения «Аппарат управления»</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/nspk-logo.png" class="feedback-logo" height="45" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first feedback-text-quote-last">
								В Eduson прекрасно оформлена аналитика, которой мы активно пользуемся, так как у нас есть регулярная полугодовая и годовая отчетность.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Инна Карелина</p>
								<p>Руководитель по оценке, обучению и развитию персонала</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/kazahk-telecom-logo.png" class="feedback-logo" height="75" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first feedback-text-quote-last">
								За время нашего партнерства команда Эдюсон проявила себя как профессионалы, качественно и быстро решающие поставленные задачи и возникающие вопросы.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Берик Битабаров</p>
								<p>Управляющий директор по персоналу</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/danfoss-logo.png" class="feedback-logo" height="70" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first feedback-text-quote-last">
								Платформа Eduson – эффективный инструмент для быстрой адаптации новых сотрудников в компании. Они проходят тестирование, руководители назначают
								им программы обучения. Нам важно развивать soft skills у отраслевых сотрудников, большая библиотека Eduson в этом помогает.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Анна Каткова</p>
								<p>Директор по персоналу</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/bi-group-logo.png" class="feedback-logo" height="50" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first">
								Отличительной чертой Эдюсон является пополняющаяся база онлайн-курсов высокого качества по широкому спектру направлений.
							</p>
							<p class="feedback-text feedback-text-quote-last">
								Большой набор цифровых материалов позволяет эффективно обучать слушателей посредствам передовых форматов.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Баспаева Ольга</p>
								<p>Директор BI University</p>
							</div>
						</div>
					</Slide>
					<Slide class="feedback-card">
						<div class="feedback-text-holder">
							<div class="feedback-header">
								<img src="../../../assets/images/welcome/megapolis-kaz-logo.png" class="feedback-logo" height="75" alt="">
							</div>
							<p class="feedback-text feedback-text-quote-first feedback-text-quote-last">
								Эдюсон для нас – это возможность получить “прокачку” из любой точки мира, достаточно иметь при себе доступ к интернету, проходить
								обучение без отрыва от работы или домашних дел. Постоянно обновляющийся каталог курсов на русском и английском языках.
							</p>
							<div class="feedback-author-holder">
								<p class="bold-font">Валерия Кан</p>
								<p>Менеджер по обучению и развитию</p>
							</div>
						</div>
					</Slide>
					<template #addons>
						<Navigation />
					</template>
				</Carousel>
			</template>
			<template v-if="currentLocale === 'en'">
				<Carousel
					:itemsToShow="1"
					:itemsToScroll="1"
					snapAlign="start"
					:wrapAround="true"
					class="feedback-wrapper"
				>
					<Slide class="">
						<div class="feedback-card">
							<div class="feedback-text-holder">
								<div class="feedback-header">
									<img src="../../../assets/images/welcome/gett@2x.png" class="feedback-logo" height="75" alt="">
								</div>
								<p class="feedback-text feedback-text-quote-first">Training a global team is a challenge. Our marketing and sales specialists work in New York, London, Moscow, and Tel-Aviv.</p>
								<p class="feedback-text">Eduson offers a modern and efficient approach to employee training. The stories from successful entrepreneurs motivate the team, while the sales techniques from the best coaches increase efficiency.</p>
								<p class="feedback-text feedback-text-quote-last">High-quality courses and a convenient interface have radically changed our employees' opinion about online learning.</p>
								<div class="feedback-author-holder">
									<span class="bold-font">CEO and Founder</span>
								</div>
							</div>
						</div>
					</Slide>
					<Slide class="">
						<div class="feedback-card">
							<div class="feedback-text-holder">
								<div class="feedback-header">
									<img src="../../../assets/images/welcome/lafarge@2x.png" class="feedback-logo" height="60" width="210" alt="" style="width:210px;">
								</div>
								<p class="feedback-text feedback-text-quote-first">In Eduson courses, complex concepts are explained in a lively, interesting way that closely reflects business realities.</p>
								<p class="feedback-text feedback-text-quote-last">High quality courses and a convenient interface have radically improved our employees' course completion ratio.</p>
								<div class="feedback-author-holder">
									<span class="bold-font">Head of Personnel Recruitment, <br />Assessment and Adaptation Department</span>
								</div>
							</div>
						</div>
					</Slide>
					<template #addons>
						<Navigation />
					</template>
				</Carousel>
			</template>
		</div>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'WhyEduson',

	components: {
		Carousel,
		Slide,
		Navigation,
	},

	data() {
		return {
			currentLocale: window.currentLocale
		}
	}
}
</script>
