<script>
export default {
	name: 'BlogForm',
	template: `<slot v-bind="self" />`,

	props: {
		initial_post: {
			type: Object,
			required: true
		},
		company_id: {
			type: String
		},
		companies_posts_path: {
			type: String,
			required: true
		},
	},

	data() {
		return {
			post: this.initial_post,
			imageUrl: '',
			datepickers: {},
			formElement: null,
		}
	},

	computed: {
		self: function() {
			return this;
		},
	},

	mounted() {
		this.formElement = document.querySelector('.j-blog-form');
		this.initializeDatepickers();
	},

	methods: {
		initializeDatepickers() {
			this.formElement.querySelectorAll('.j-datepicker').forEach((datepicker) => {
				let options = {
					field: datepicker,
					theme: 'pikaday-theme',
					firstDay: 1 * moment().startOf('week').format('E'),
					i18n: {
						months: _.map(moment.months(), capitalize),
						weekdays: _.map(moment.weekdays(), capitalize),
						weekdaysShort: _.map(moment.weekdaysShort(), capitalize)
					},
					onSelect: function(date) {
						datepicker.value = this.post.created_at.toString();
					}
				};

				if (I18n.locale == 'ru') {
					_.extend(options.i18n, { previousMonth: 'Предыдущий месяц', nextMonth: 'Следующий месяц' });
				}

				this.datepickers[datepicker.dataset.model] = new Pikaday(options);
			})
		},

		onFileChange(event) {
			const file = event.target.files[0];
			if (file) {
				this.post.picture = file;
				this.imageUrl = URL.createObjectURL(file);
			}
		},

		transliterate(text) {
			const translitMap = {
				'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'yo',
				'ж': 'zh', 'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm',
				'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
				'ф': 'f', 'х': 'kh', 'ц': 'ts', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch',
				'ъ': '', 'ы': 'y', 'ь': '', 'э': 'e', 'ю': 'yu', 'я': 'ya', ' ': '-'
			};

			return text.split('').map(char => {
				return translitMap[char] || char;
			}).join('');
		},

		updateUrl() {
			this.post.url = this.transliterate(this.post.name.toLowerCase());
		}
	}
}
</script>
