<template>
	<button
		class="cd-leftbar__link cd-leftbar__link--menu"
		@click="bthHandler" type="button"
	>
		<span v-if="isOpen" class="cd-leftbar__name">
			{{ $t('buttons.open_menu') }}
		</span>
		<span v-else class="cd-leftbar__name">
			{{ $t('buttons.collapse_menu') }}
		</span>
	</button>
</template>

<script>
export default {
	name: 'CabinetMenuToggler',

	mounted() {
		this.pageElement = document.querySelector('.j-cd-page');
		this.leftbarElement = document.querySelector('.j-cd-leftbar');
	},

	data() {
		return {
			isOpen: true,
			pageElement: '',
			leftbarElement: ''
		}
	},

	methods: {
		bthHandler(e) {
			e.preventDefault();
			e.currentTarget.classList.toggle('_is-open-leftbar');
			this.pageElement.classList.toggle('_is-open-leftbar');
			this.leftbarElement.classList.toggle('_is-open-leftbar');
			this.isOpen = !this.isOpen;
		}
	},
}
</script>
