<template>
	<div class="event-members" v-if="liveEvenUsersPreset()">
		<div class="event-members__head">
			<div class="event-members__row">
				<div class="event-members__cell event-members__cell--info">
					<span class="mr-15">{{ t.live_events.total_invited }}: {{ countUsers() }}</span>
					<span v-if="live_event_is_over && !live_event_cancelled">{{ t.live_events.attendance }}: {{ persentPresentedUsers() }}%</span>
				</div>
				<div class="event-members__cell event-members__cell--check">
					<span v-if="userCanCheckPresent()" class="event-members__title">{{ t.live_events.check_turnout }}</span>
				</div>
			</div>
		</div>
		<div class="event-members__body scrollable-shadow scrollable-shadow--small">
			<div class="event-members__row" v-for="live_event_user in live_event_users" :key="live_event_user.id" :class="[live_event_user.was_present ? 'is-present' : '']">
				<div class="event-members__cell event-members__cell--member" :class="progressesPresent(live_event_user.progresses) && live_event_user.was_present ? '' : 'event-members__cell--wide'">
					<div class="event-members__title">
						<a v-if="live_event_user.can_read_user_stats" :href="live_event_user.user_stats_url">
						{{ live_event_user.name }}
						</a>
						<span v-else>
						{{ live_event_user.name }}
						</span>
					</div>
					<div class="event-members__descr">
						{{ live_event_user.groups }}
					</div>
				</div>
				<div class="event-members__cell event-members__cell--skills" v-if="userCanCheckPresent() && progressesPresent(live_event_user.progresses) && live_event_user.was_present">
					<div class="event-members__item" v-for="progress in live_event_user.progresses" :key="progress.id">
						<div class="event-members__title">{{ progress.title }}</div>
						<div class="event-members__skills">
							<div class="event-members__skills-item"
								v-for="score in availableScores"
								:key="progress.id_score"
								:class="{ selected: score == progress.score }"
								@click="assessCompetition(live_event_user, progress, score)"
								rel="score">
								{{ score }}
							</div>
						</div>
					</div>
				</div>
				<div class="event-members__cell event-members__cell--check" v-if="userCanCheckPresent()">
					<label class="checkbox checkbox--box checkbox--inline">
						<input class="checkbox__input" :id="live_event_user.id" v-model="live_event_user.was_present" type="checkbox">
						<span class="checkbox__label" :for="live_event_user.id" @click="toggleWasPresent(live_event_user)"></span>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	name: 'LiveEventUsers',

	props: {
		live_event_is_over: {
			type: Boolean,
			required: true
		},
		live_event_cancelled: {
			type: Boolean,
			required: true
		},
		live_event_users: {
			type: Array,
			required: false
		},
		can_edit_event: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			availableScores: [1, 2, 3, 4, 5],
			t: I18n.t
		}
	},

	methods: {
		countUsers() {
			return this.live_event_users ? this.live_event_users.length : 0;
		},

		countPresentedUsers() {
			return this.live_event_users.filter(live_event_user => live_event_user.was_present).length;
		},

		persentPresentedUsers() {
			return this.countUsers() > 0 ? Math.round(this.countPresentedUsers() * 100 / this.countUsers()) : 0;
		},

		progressesPresent(progresses) {
			return progresses.length > 0;
		},

		liveEvenUsersPreset() {
			return this.countUsers() > 0;
		},

		userCanCheckPresent() {
			return this.can_edit_event && this.live_event_is_over && !this.live_event_cancelled;
		},

		assessCompetition(live_event_user, progress, score) {
			axios({
				response_type: 'json',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				method: 'POST',
				url: progress.assess_competition_url,
				data: { score: score }
			}).then((response) => {
				live_event_user.progresses = response.data.progresses
			})
		},

		toggleWasPresent(live_event_user) {
			axios({
				response_type: 'json',
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
				},
				method: 'POST',
				url: live_event_user.was_present_url,
			}).then((response) => {
				live_event_user.was_present = response.data.was_present
			})
		}
	}
}
</script>
