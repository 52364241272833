<template>
	<ol class="content-with-nav__nav-list" ref="nav_list">
		<li v-for="item in navItems" v-html="item" class="content-with-nav__longread-item"></li>
	</ol>
</template>

<script>
import hljs from 'highlight.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';

export default {
	name: 'Longread',

	data() {
		return {
			headings: '',
			navItems: [],
			players: [],
		}
	},

	mounted() {
		this.initHeadings();
		this.highlightCode();
		this.initVideoJs();
		this.initImages();
	},

	methods: {
		initHeadings() {
			document.querySelectorAll('.j-longread h1, .j-longread h2, .j-longread h3').forEach((heading, index) => {
				if (heading.tagName === 'H1') {
					heading.id = 'h1-' + index;
					this.navItems.push(`<a href="#h1-${index}" class="content-with-nav__nav-link content-with-nav__nav-link--1lvl link-btn link-btn--dark-font j-scroll-to-link">${heading.innerHTML}</a>`);
				}

				if (heading.tagName === 'H2') {
					heading.id = 'h2-' + index;
					this.navItems.push(`<a href="#h2-${index}" class="content-with-nav__nav-link content-with-nav__nav-link--1lvl link-btn link-btn--dark-font j-scroll-to-link">${heading.innerHTML}</a>`);
				}

				if (heading.tagName === 'H3') {
					heading.id = 'h3-' + index;
					this.navItems.push(`<a href="#h3-${index}" class="content-with-nav__nav-link content-with-nav__nav-link--2lvl link-btn link-btn--dark-font j-scroll-to-link">${heading.innerHTML}</a>`);
				}
			})
		},

		highlightCode() {
			document.querySelectorAll('.j-longread pre code').forEach((el) => {
				hljs.highlightElement(el);
			});
		},

		initImages() {
			document.querySelectorAll('.j-longread img').forEach((img) => {
				img.closest('p, figure').style.display = 'inline-block';
			})
		},

		initVideoJs() {
			document.querySelectorAll('.j-longread video').forEach((video) => {
				video.classList.add('video-js');

				let playBtn = document.createElement('button');
				playBtn.type = 'button';
				playBtn.className = 'play-btn';
				playBtn.innerHTML = '<span class="play-btn__icon"></span>';
				video.closest('div').appendChild(playBtn);

				let player = videojs(video, {
					autoplay: false,
					controls: true,
					preload: false,
					width: 656,
					height: 369,
					title: '',
					playbackRates: [0.5, 1, 1.25, 1.5, 2],
					sources: [
						{
							src: video.src,
							type: 'video/mp4'
						}
					]
				});

				playBtn.addEventListener('click', () => {
					player.play();
					playBtn.classList.add('not-showing');
				})

				this.players.push(player);
			})
		}
	},

	beforeDestroy() {
		this.players.forEach((player) => {
			if (player) {
				player.dispose();
			}
		})
	}
}
</script>

