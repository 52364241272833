<template>
	<section class="welcome-partners" id="welcome-partners">
		<div class="page-inner">
			<h2 class="welcome-partners__title" v-html="$t('welcome_partners_title')"></h2>
			<Carousel
				class="welcome-partners__slider"
				:itemsToShow="1"
				:itemsToScroll="1"
				snapAlign="start"
				:autoplay="3000"
				:wrapAround="true"
			>
				<template v-if="currentLocale === 'ru'">
					<Slide class="welcome-partners__item">
						<img src="../../../assets/images/welcome/welcome-logos-1-ru.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-2-ru.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-3-ru.jpg" alt="" width="960" height="98">
					</Slide>
					<Slide class="welcome-partners__item">
						<img src="../../../assets/images/welcome/welcome-logos-4-ru.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-5-ru.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-6-ru.jpg" alt="" width="960" height="98">
					</Slide>
				</template>
				<template v-else>
					<Slide class="welcome-partners__item">
						<img src="../../../assets/images/welcome/welcome-logos-1-en.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-2-en.jpg" alt="" width="960" height="98">
					</Slide>
					<Slide class="welcome-partners__item">
						<img src="../../../assets/images/welcome/welcome-logos-3-en.jpg" alt="" width="960" height="98">
						<img src="../../../assets/images/welcome/welcome-logos-4-en.jpg" alt="" width="960" height="98">
					</Slide>
				</template>
				<template #addons>
					<Navigation />
				</template>
			</Carousel>
		</div>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'WelcomePartners',

	components: {
		Carousel,
		Slide,
		Navigation,
	},

	data() {
		return {
			currentLocale: window.currentLocale,
		}
	}
}
</script>
