import axios from "axios"

const videoEvents = {
    intro_started_event(player, options) {
        if (options.sending) return

        const { path, eventable_id, eventable_type } = options
        const name = "intro_started"
        const time = player.currentTime()

        if (time > 3) {
            trackEvent(path, { eventable_id, eventable_type, name })
                .then(_res => options.sending = true)
                .catch(errors => console.log(errors))
        }
    },

    intro_watched_event(player, options) {
        if (options.sending) return

        const { path, eventable_id, eventable_type } = options
        const name = "intro_watched"
        const time = player.currentTime()
        const duration = player.duration()

        if (time > duration * 0.9) {
            trackEvent(path, { eventable_id, eventable_type, name })
                .then(_res => options.sending = true)
                .catch(errors => console.log(errors))
        }
    },
}

function trackEvent(path, data) {
    return axios({
        response_type: "json",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        url: path,
        method: "POST",
        data: data
    })
}

export default videoEvents
