<template>
	<section class="catalog__section">
		<div class="catalog__section-header catalog__section-header--with-link">
			<h2 class="catalog__title">
				{{ $t('wish_courses') }}
			</h2>
			<p class="catalog__right">
				<a href="/my_learning_plan" class="link-btn link-btn--opacity link-btn--light-font">
					{{ $t('buttons.wishlist_all') }}
				</a>
			</p>
		</div>
		<Carousel
			v-if="wishes.length"
			class="catalog__list catalog__list--plan"
			:itemsToShow="4"
			:itemsToScroll="4"
			snapAlign="start"
		>
			<Slide
				v-for="(course, index) in wishes"
				:key="'section-' + course.id + '-' + index"
				class="catalog__item"
			>
				<CoursePreview
					:course="course"
					:full_only="false"
					:finished="course.finished"
					:started="course.active"
					:bookmark="true"
				/>
			</Slide>
			<template v-if="wishes.length > 4" #addons>
				<Navigation />
			</template>
		</Carousel>
		<template v-else>
			<div class="page-card page-card--bordered message-card message-card--inline message-card--with-centred-icon message-card--empty-learning-plan">
				<p v-if="current_company !== 'megafon'" class="message-card__label">{{ $t('messages.no_wished_plan') }}</p>
				<p class="message-card__title">{{ $t('messages.choose_description') }}</p>
				<a href="/courses" class="regular-btn action-btn">
					{{ $t('buttons.choose_course') }}
				</a>
			</div>
		</template>
	</section>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import CoursePreview from "./Catalog/CoursePreview.vue";

export default {
	name: "WishSelection",

	components: {
		Carousel,
		Slide,
		Navigation,
		CoursePreview
	},

	props: {
		wishes: Array,
		learning_plan_result: String,
		newCourses: {
			type: Boolean,
			default: false,
		},
		current_company: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			uncompletedOnly: false,
			loading: true,
			isContinue: false,
		};
	}
};
</script>
