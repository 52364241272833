<template>
	<video ref="videoPlayer" class="video-js"></video>
	<div class="course-intro-mute-toggle" v-if="muted && options.mute_message" @click="unmute()">
		<span class="course-intro-mute-message">{{ options.mute_message }}</span>
	</div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css';
import videojs_i18n from "../../../lib/videojs_i18n.js"
import videoEvents from "../../../lib/video_events.js"

export default {
	name: "VideoPreview",
	props: {
		options: { type: Object },
		events: { type: Object, default() { return {} } }
	},

	data() {
		return {
			player: null,
			muted: (this.options.mute || false)
		}
	},

	mounted() {
		videojs.addLanguage("ru", videojs_i18n.ru)
		this.player = videojs(this.$refs.videoPlayer, this.options)
		this.player.on("timeupdate", this.onPlayerTimeUpdate)
	},

	methods: {
		onPlayerTimeUpdate(e) {
			if (!this.events?.timeupdate) return

			Object.keys(this.events.timeupdate).forEach((eventName) => {
				const eventHandler = videoEvents[`${eventName}_event`]
				if (typeof eventHandler == "function") {
				eventHandler(this.player, this.events.timeupdate[eventName])
				}
			})
		},

		unmute: function() {
			this.player.muted(false)
			this.muted = false
		}
	},
}
</script>
