<template>
	<section v-if="isShow" class='announce announce--banner announce--invest mb-20'>
		<div class='announce__inner'>
			<div class='announce__text-holder'>
				<h3 class='announce__title mb-3'>Инвестируй в свою карьеру!</h3>
				<p class="announce__text announce__text--large">Промокод <span class="bold-font">DEMO</span> — 3000 бонусных рублей на полный курс</p>
			</div>
			<a :href="url" class="announce__btn regular-btn action-btn" target="_blank" rel="noopener noreferrer">Забронировать скидку</a>
		</div>
	</section>
</template>

<script>
import demoFreeCourses from '../../common/demo-free-courses.js';

export default {
	name: 'InvestBanner',

	props: {
		current_company: ''
	},

	data() {
		return {
			url: '',
			isShow: false,
			courses: demoFreeCourses
		}
	},

	mounted() {
		this.courses.forEach((course) => {
			if (course.name === this.current_company) {
				this.isShow = true;
				this.url = course.url;
			}
		});
	},
}
</script>

