<template>
	<div v-show="isFullsizeImage" class="longread-fullsize" aria-hidden="true" @click="closeFullsizeImage">
		<button class="remove-btn remove-btn--white" type="button">
			<span class="visually-hidden">{{ $t('buttons.close') }}</span>
		</button>
		<img id="fullsize-image" class="longread-fullsize__image" src="" alt="" width="" height="">
	</div>
</template>

<script>
export default {
	name: 'FullsizeImage',

	data() {
		return {
			isFullsizeImage: false
		}
	},

	mounted() {
		this.initFullsizeImages();
	},

	methods: {
		initFullsizeImages() {
			document.querySelectorAll('.j-fullsize-images img').forEach((img) => {
				img.closest('p, figure, div, td').classList.add('_is-fullsize')
				img.addEventListener('click', () => {
					this.isFullsizeImage = true;
					const fullsizeImage = document.querySelector('#fullsize-image');
					fullsizeImage.src = img.src;
				})
			})
		},

		closeFullsizeImage(e) {
			if (!e.target.classList.contains('longread-fullsize__image')) {
				this.isFullsizeImage = false;
			}
		},
	}
}
</script>

